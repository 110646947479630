
.about .bg-orange {
    position: relative;
    overflow: hidden;
  }
  
  .about .bg-orange::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; /* Make sure the pseudo-element covers the full container */
    background-color: var(--color-orange);
    transform: skewX(10deg); /* Skew in the opposite direction for \ effect */
    transform-origin: bottom left; 
    z-index: -1; /* Place the background behind the image */
  }
  
  .about .bg-orange img {
    position: relative;
    z-index: 1;
    width: 100%; /* Adjust the image to fit within the container */
    transform: none; /* Remove any skew transformation on the image */
  }
  
  .about .bg-yellow {
    position: relative;
    margin-left: -5rem;
    overflow: hidden;
  }
  
  .about .bg-yellow::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; 
    background-color: var(--color-yellow);
    transform: skewX(10deg); 
    transform-origin: top right; 
    z-index: -1; 
  }
  
  .about .bg-yellow img {
    position: relative;
    z-index: 1;
    width: 100%;
    transform: none; 
    
  }

  .foundertext {
    line-height: 2rem;
    padding: 3rem;
    font-weight: 300;
    text-align: center;
  }

  .story-text {
    background: #f9f4e8 !important;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "Times New Roman", serif;
  }


  .founder{
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width:990px) {
    .about .bg-orange {
      background-color: var(--color-orange);
    }

    .about .bg-yellow {
      background-color: var(--color-yellow);
    }
  }
  @media only screen and (max-width:450px) {
    .about .bg-orange {
      background-color: var(--color-orange);
    }

    .about .bg-yellow {
      background-color: var(--color-yellow);
    }
  }