
.color a {
    text-decoration: none;
    text-decoration: none;
   color: rgb(254,39,112);
   margin-left: 2rem;

}

.color {
    color: rgb(254,39,112);

}

.our-products img{
    height: 20rem ;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}