/* Footer */
footer .logo {
  width: auto;
}
footer h3 {
  margin-bottom: 1.5rem;
}
footer a {
  text-decoration: none;
}

footer ul li {
  margin-bottom: 0.5rem;
}

footer .social-links a {
  font-size: 1.2rem;
}
.copyright {
  background: var(--color-orange);
  color: white;
  padding: 1rem 0rem;
  width: 100%;
}
.copyright a {
  color: var(--color-yellow);
  text-decoration: none;
}
footer li {
  transition: all 300ms ease-in;
}
footer li:hover {
  padding-left: 0.5rem;
  color: var(--color-orange);
}
footer li:hover a {
  color: var(--color-yellow) !important;
}
