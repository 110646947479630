.Bhimeshwari img {
    margin-top: -10%;
  width: 100%; /* Ensure image takes up full width of its container */
  transform: scale(0.8);
  transition: transform 0.3s ease-in-out; /* Smooth transition for zoom effect */
}

.Bhimeshwari img:hover {
    transform: scale(1);      /* Zoom in to 120% on hover */
}


