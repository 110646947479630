header .above-line {
  background: var(--color-orange);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 0.7rem 3rem;
  color: white;
}

header .above-line a {
  color: white;
  text-decoration: none;
}

header .single-info {
  display: inline;
  margin-right: 1rem;
}

header .single-info i {
  padding-right: 0.5rem;
}

header .social {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.9rem;
}

/* ========= Nav =======  */
header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: white;
  position: absolute;
  z-index: 999;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-top: 1rem; */
  border-radius: 15px;
  padding: 0.25rem 1rem;
}

header nav .logo img {
  height: 100px;
}

header nav .bar {
  display: none;
}
header nav .nav-links {
  gap: 1rem !important;
  display: flex;
  font-weight: 500;
  font-size: medium;
}
header nav .nav-links li a {
  color: black;
  text-decoration: none;
  transition: all 300ms ease-in;
}
header nav .nav-links li a:hover {
  color: var(--color-orange);
}
header nav .nav-links li a.active {
  color: var(--color-orange);
}

header .donate-btn {
  padding: 0.5rem 2rem;
  background: var(--color-orange);
  color: white;
  text-decoration: none;
  border-radius: 50rem;
  font-size: 1rem;
}

/* @media only screen and (max-width:1500px) {
    .header nav  {
        
    }
} */

@media only screen and (max-width: 1300px) {
  .hum-drop-down {
    color: black !important;
  }

  header nav {
    position: relative;
  }

  header nav .nav-links li a:hover {
    color: white !important;
  }
  header .position-relative {
    position: relative;
  }
  header nav .side-flex {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: row-reverse;
  }
  header nav .side-flex .bar {
    font-size: 1.5rem;
    color: var(--color-orange);
  }
  header nav .bar {
    display: block;
  }
  header .position-relative {
    margin-top: 5rem;
  }
  header nav .nav-links li a {
    color: white;
    text-transform: uppercase;
  }
  header .nav-links {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.9rem;
    position: absolute;
    background: var(--color-orange);
    border-radius: 15px;
    overflow: hidden;
    height: 0;

    transform: translateX(-50%);
    width: 60vw;
    top: 100%;
    transition: all 300ms ease-in;
  }
  header .nav-links.active {
    height: max-content;
    padding: 2rem 0rem;
  }

  header nav {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    flex-direction: column;
  }
}

@media only screen and (max-width: 450px) {
  header nav .donate-btn {
    display: none;
  }
  header .above-line {
    padding: 0.2rem 0rem;
  }
  header .above-line {
    display: none;
  }
  header .nav-links {
    transform: translateX(-70%);
  }
}

/* ==================== music_float =================  */
.music_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 240px; /* Adjusted position for stacking */
  right: 40px;
  background-color: #ff4081; /* Example color for music */
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  transform: all 0.5s ease;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.music_float::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background-color: #ff4081; /* Match with the background */
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.music_float:focus {
  border: none;
  outline: none;
}

.music-icon {
  margin-top: 16px; /* Adjust if needed */
}

/* ==================== Whatsapp =================  */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff !important;
  border-radius: 50px;
  text-align: center;
  /* font-size: 30px; */
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  transform: all 0.5s ease;
  background-color: #25d366;
  line-height: 50px;
}

.whatsapp_float .fa-whatsapp {
  color: white;
}

.whatsapp_float::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background-color: #25d366;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.whatsapp_float:focus {
  border: none;
  outline: none;
}

.whatsapp-icon {
  margin-top: 16px;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 1;
  }
}

/* ==================== call_float =================  */
.call_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 140px;
  right: 40px;
  background-color: #0067b0;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 25px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  transform: all 0.5s ease;
  background-color: #0067b0;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.call_float::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background-color: #0067b0;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.call_float:focus {
  border: none;
  outline: none;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 1;
  }
}
