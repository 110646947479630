@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    font-family: "Poppins", sans-serif !important;
}


:root{
    --color-black: #000000;
    --color-orange: #F05A28;
    --color-yellow: #FBD45A;
    --color-cream:  #F9F4E8;


}

.title-head{
    text-align: center;
    color: var(--color-orange);
    padding: 1.5rem 0rem;
    margin-top: 1rem;
    font-weight: 600;
    font-size: 2.2rem;
}
img{
    width: 100%;
}


.grid-3{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
}
.grid-3 img{
    width: 100%;
    border-radius: 5px;
}


@media only screen and (max-width:950px){
    .grid-3{
        grid-template-columns: repeat(2,1fr);
    }
}
@media only screen and (max-width:350px){
    .grid-3{
        grid-template-columns: repeat(1,1fr);
    }
}


.hero{
    background-image: url('./joinus.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 10rem;
    padding-bottom: 5rem;
    text-align: start;
    color: white;
}
.home-hero .title{
    font-size: 2.5rem !important;
    font-weight: 400 !important;
}

.home-hero .btns{
    display: flex;
    gap: 1rem;
   
}


/* ======= bread-title  */
.hero .bread-title{
    text-align: center;
    padding: 3rem 0rem;
}

.btn-Submit {
    padding: 0.8rem 2rem;
    background: var(--color-orange);
    color: white;
    text-decoration: none;
    border-radius: 50rem;
    font-size: 1rem;
    border-style: none;
}
.mybtn {
    display: inline-block;
    background-color: #F05A28; /* Button background color */
    color: #fff; /* Text color */
    padding: 10px 30px; /* Padding for the button */
    font-size: 16px; /* Font size */
    text-align: center; /* Center the text */
    border-radius: 50px; /* More rounded corners */
    text-decoration: none; /* Remove underline from the link */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow */
    margin-top: 10px; /* Margin from the top */
    margin-left: auto;
    margin-right: auto;
}


  